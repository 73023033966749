import { Component } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { ProductListing } from 'src/app/Models/productListing.model';
import { NavService } from 'src/app/core/services/nav.service';
import { ProductListingService } from '../product-listing.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { StatusId } from 'src/app/Models/organization.model';
import { Criterion } from 'src/app/Models/criterion.model';
import { TypeId } from 'src/app/Models/vendorItem.model';
import { ProductDisplayResponse } from 'src/app/Models/productDisplayResponse.model';
import { ProductDisplayRequest } from 'src/app/Models/productDisplayRequest.model';
import { MasterItem } from 'src/app/Models/masterItem.model';

@Component({
  selector: 'app-product-listing-form',
  templateUrl: './product-listing-form.component.html',
  styleUrls: ['./product-listing-form.component.scss']
})
export class ProductListingFormComponent {

  public mode: 'create' | 'edit' = 'edit';
  public productListing: ProductListing = new ProductListing();
  private ProductListingSubscription: Subscription | undefined;
  productListingForm: FormGroup;
  previousUrl: string;
  currentStep: number = 1;
  StatusId = StatusId;
  modalRef: NgbModalRef | undefined;
  products: ProductDisplayResponse;
  products$: Observable<ProductDisplayResponse | null>;
  stepsList: number[] = [1, 2, 3];

  constructor(private route: ActivatedRoute, private router: Router, private service: ProductListingService, private fb: FormBuilder, private navServices: NavService, private modalService: NgbModal) {

  }

  ngOnInit(): void {
    this.navServices.collapseSidebar = true;
    this.mode = this.route.snapshot.params['mode'] || 'edit';

    this.ProductListingSubscription = this.service.selectedProductList$.subscribe(productListing => {

      this.productListing = productListing || new ProductListing();
      if (this.mode === 'create') {
        this.productListing.criteria = [];
      } else {
        this.getCriteria(this.productListing.id).subscribe(criteria => {
          if (criteria != null) {
            this.productListing.criteria = criteria;
          }
        });
      }
      console.log('Mode:', this.mode, this.productListing);
      this.initializeForm();
    });
  }

  initializeForm() {
    this.productListingForm = this.fb.group({
      currentStep: [1],
      steps: this.fb.array([
        this.fb.group({
          Name: [this.productListing.name || '', Validators.required],
          Status: [this.getDefaultStatus(), Validators.required],
        }),
        this.fb.group({
          Category: [this.getCategories() || ''],
          Brand: [this.getBrands() || ''],
        }),
        this.fb.group({
        }),
      ]),
    });
  }

  async openVerticallyCentered(content) {
    this.modalRef = this.modalService.open(content, { centered: true });

    this.modalRef.hidden.subscribe(() => {
      this.modalRef = undefined;
    });
  }

  getTitleByStep(): string {
    switch (this.currentStep) {
      case 1:
        return 'General Information';
      case 2:
        return 'Categories and Brands';
      case 3:
        return 'Products';
      default:
        return 'Step ' + this.currentStep;
    }
  }

  getDefaultStatus(): StatusId {
    if (this.mode === 'create') {
      return StatusId.Enabled;
    } else if (this.mode === 'edit') {
      return this.productListing.status || StatusId.Enabled;
    }
    return StatusId.Enabled;
  }

  get currentStepForm(): FormGroup {
    return (this.productListingForm.get('steps') as FormArray).at(this.currentStep - 1) as FormGroup;
  }

  get steps(): FormArray {
    return this.productListingForm.get('steps') as FormArray;
  }

  getRequiredValidator(controlName: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {

      const categoryControl = this.productListingForm?.get('steps')?.get('1')?.get('Category');
      const brandControl = this.productListingForm?.get('steps')?.get('1')?.get('Brand');

      if (categoryControl && brandControl) {
        categoryControl.valueChanges.subscribe(value => {
        });

        brandControl.valueChanges.subscribe(value => {
        });
      }

      const isCategorySelected = categoryControl?.value?.length > 0;
      const isBrandSelected = brandControl?.value?.length > 0;

      if (controlName === 'Category' && !isBrandSelected) {
        return null;
      }

      if (controlName === 'Brand' && !isCategorySelected) {
        return null;
      }

      return Validators.required(control);
    };
  }

  getProducts(pageNumber?: number) {
    if (this.productListing.criteria.length !== 0) {
      var request = new ProductDisplayRequest;
      request.pageSize = 10;
      request.pageNumber = pageNumber || 1;
      request.criteria = this.productListing.criteria;

      this.products$ = this.service.getProductList(request);
    }

  };

  getCriteria(id: number): Observable<Criterion[]> {
    return this.service.getCriteria(id);
  }

  cancel() {
    this.router.navigate(['/main/products/productListing/productListing']);
  }

  prevStep() {
    this.currentStep = Math.max(1, this.currentStep - 1);
  }

  nextStep() {
    if (this.isCurrentStepFormValid()) {
      this.currentStep = Math.min(this.steps.length, this.currentStep + 1);

      if (this.currentStep === 2 || this.currentStep === 3) {
        if (this.productListing.criteria.length !== 0) {
          this.getProducts();
        }
      }
    } else {
      console.log('Step', this.currentStep, 'form is NOT valid.');
    }
  }

  closeModal() {
    if (this.modalRef) {
      this.modalRef.dismiss('Cross click');
    }
  }

  saveProfile() {
    this.productListingForm.markAllAsTouched();

    let updatedProductListing: ProductListing = new ProductListing();

    if (this.productListingForm.valid) {

      const nameControl = this.productListingForm.get('steps.0.Name');
      const statusControl = this.productListingForm.get('steps.0.Status');

      if (nameControl && statusControl) {
        this.productListing.name = nameControl.value;
        this.productListing.status = statusControl.value;
      }

      updatedProductListing = this.productListing;
      this.productListing.criteria = this.productListing.criteria;

      this.service.saveProductListing(updatedProductListing, this.mode)
      .subscribe(
        response => {
          console.log('Profile saved successfully:', response);
          this.router.navigate(['/main/listing']);
        },
        error => {
          alert('Save product listing request failed.');
          console.error('Save product listing request failed:', error);
        }
      );
    }
  }

  selectStatus(status: StatusId | string) {
    if (typeof status === 'string' || Object.values(StatusId).includes(status)) {
      this.currentStepForm?.get('Status')?.setValue(status, { emitEvent: false });
    }
  }

  toggleButton(productListing: ProductListing) {
    if (productListing.status === StatusId.Enabled) {
      productListing.status = StatusId.Disabled;
    } else {
      productListing.status = StatusId.Enabled;
    }
    this.currentStepForm?.get('Status')?.setValue(productListing.status);
    this.selectStatus(productListing.status)
  }

  isCurrentStepFormValid(): boolean {
    if (this.currentStep === 2) {
      return this.productListing.criteria.length > 0;
    }
    return this.currentStepForm.valid;
  }

  criteriaSelected(item: Criterion) {
    const index = this.productListing.criteria.findIndex(existingItem => existingItem.valueItemId === item.valueItemId && existingItem.typeId === item.typeId);

    if (index === -1) {
      this.productListing.criteria.push(item);
      this.service.setCriteria(this.productListing.criteria);

      const categoryControl = this.productListingForm?.get('steps')?.get('1')?.get('Category');
      const brandControl = this.productListingForm?.get('steps')?.get('1')?.get('Brand');

      if (item.typeId === TypeId.Categories) {
        const categories = this.getCategories();
        categoryControl?.patchValue(categories);
      } else if (item.typeId === TypeId.Brands) {
        const brands = this.getBrands();
        brandControl?.patchValue(brands);
      }

      this.productListingForm.updateValueAndValidity();
    }
  }

  removeCategory(id: number) {
    const index = this.productListing.criteria.findIndex(existingItem => existingItem.valueItemId === id && existingItem.typeId === TypeId.Categories);
    if (index !== -1) {
      this.productListing.criteria.splice(index, 1);
      this.service.setCriteria(this.productListing.criteria);

      const categoryControl = this.productListingForm?.get('steps')?.get('1')?.get('Category');

      const categories = this.getCategories();
      categoryControl?.patchValue(categories);

      this.productListingForm.updateValueAndValidity();
    }
  }

  removeBrand(id: number) {
    const index = this.productListing.criteria.findIndex(existingItem => existingItem.valueItemId === id && existingItem.typeId === TypeId.Brands);
    if (index !== -1) {
      this.productListing.criteria.splice(index, 1);
      this.service.setCriteria(this.productListing.criteria);

      const brandControl = this.productListingForm?.get('steps')?.get('1')?.get('Brand');

      const brands = this.getBrands();
      brandControl?.patchValue(brands);

      this.productListingForm.updateValueAndValidity();
    }
  }

  getCategories(): Criterion[] {
    return this.productListing.criteria?.filter(x => x.typeId === TypeId.Categories)
  }

  getBrands(): Criterion[] {
    var brands = this.productListing.criteria?.filter(x => x.typeId === TypeId.Brands)
    return brands
  }

  getCategoriesName(): MasterItem[] {
    const categories = this.productListing.criteria?.filter(x => x.typeId === TypeId.Categories)
      .map(x => this.service.getCategoryById(x.valueItemId))
      .filter(Boolean) as MasterItem[];
    return categories;
  }

  getBrandsName(): MasterItem[] {
    const brands = this.productListing.criteria?.filter(x => x.typeId === TypeId.Brands)
      .map(x => this.service.getBrandById(x.valueItemId))
      .filter(Boolean) as MasterItem[];
    return brands;
  }

  updatePage(pageNumber: number) {
    if (this.currentStep == 3) {
      this.getProducts(pageNumber);
      window.scrollTo({ top: 0, behavior: 'auto' });
    }
  }

  ngOnDestroy(): void {
    if (this.ProductListingSubscription) {
      this.ProductListingSubscription.unsubscribe();
    }
  }
}
