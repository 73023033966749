import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterByUnmapped'
})
export class FilterByUnmappedPipe implements PipeTransform {

  transform(filteredVendorItemList: any[], isUnmappedOnly: boolean): any[] {
    if (!isUnmappedOnly) {
      return filteredVendorItemList;
    }
    return filteredVendorItemList?.filter(item => item.systemItemValue === null);
  }

}
