import { Component ,Input} from '@angular/core';
import { RelatedProductsMappingItem } from 'src/app/Models/relatedProductsMappingItem.model';

@Component({
  selector: 'app-photo-carousel',
  templateUrl: './photo-carousel.component.html',
  styleUrls: ['./photo-carousel.component.scss']
})
export class PhotoCarouselComponent {

  @Input()
  relatedProducts : RelatedProductsMappingItem[];

  ngOnInit() {
    console.log('Related Products:', this.relatedProducts);

  }

  owlcarousel1Options = {
    loop: true,
    margin: 10,
    nav: false,
    responsive: {
      0: {
              items: 4
            },
            400: {
              items: 4
            },
            740: {
              items: 4
            },
            940: {
              items: 4
            }
    },
  };
  // loop: true,
  //   mouseDrag: false,
  //   touchDrag: false,
  //   pullDrag: false,
  //   dots: false,
  //   navSpeed: 700,
  //   navText: ['', ''],
  //   responsive: {
  //     0: {
  //       items: 1
  //     },
  //     400: {
  //       items: 2
  //     },
  //     740: {
  //       items: 3
  //     },
  //     940: {
  //       items: 4
  //     }
  //   },
  //   nav: true
  // }
}
