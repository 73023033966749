<div class="container">
  <div class="header-row main-title">
      <h3 class="title">Product Listing</h3>
      <button class="btn" type="button" placement="top" ngbTooltip="Create Product Listing"
          (click)="createProductListing()">
          Create Product Listing
      </button>
  </div>
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-body custom-datatable noscroll">
                    <table class="table">
                        <thead>
                            <tr>
                                <th scope="col">Name</th>
                                <th scope="col">Creation Date</th>
                                <th scope="col">Enable</th>
                                <th scope="col">Edit</th>
                                <th scope="col"></th>
                                <th scope="col">Delete</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let productListingItem of tableProductListings$ | async ">
                                <td>{{productListingItem.name}}</td>
                                <td>{{productListingItem.creationDate| date}}</td>
                                <td>
                                    <div class="toggle"
                                        [class.disabled]="productListingItem.status !== StatusId.Enabled"
                                        (click)="toggleButton(productListingItem)">
                                        <i
                                            [class]="productListingItem.status !== StatusId.Enabled ? 'fa fa-toggle-off' : 'fa fa-toggle-on green'"></i>
                                    </div>
                                </td>
                                <td><button (click)="edit(productListingItem)"><i
                                            class="fa fa-edit"></i></button></td>
                                <td>
                                    <button (click)="downlodExcel(productListingItem)">
                                        <ng-container
                                            *ngIf="!loadingStatus[productListingItem.id]; else spinnerTemplate">
                                            <i class="fa fa-file-excel-o green"></i>
                                        </ng-container>
                                        <ng-template #spinnerTemplate>
                                            <i class="fa fa-spinner fa-spin green"></i>
                                        </ng-template>
                                    </button>
                                </td>
                                <td>
                                  <button (click)="remove(productListingItem.id)">
                                    <i class="fa fa-trash-o red"></i>
                                  </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <!-- <div class="d-flex justify-content-between p-2">
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</div>
