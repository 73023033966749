import { HttpClient } from '@angular/common/http';
import { Injectable, Type } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'environments/environment';
import { Observable, catchError, throwError } from 'rxjs';
import { TypeId, VendorItem } from '../Models/vendorItem.model';
import { MasterItem } from '../Models/masterItem.model';
import { RelatedProductsMappingItem } from '../Models/relatedProductsMappingItem.model';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MappingService {


  private URL = `${environment.apiUrl}/Mapping`;
  private _http: any = null;

  constructor(private http: HttpClient) {
    this._http = http;
  }

  getVendorList(supplierId: number, taxonomy: TypeId): Observable<VendorItem[]> {
    return this.http.get<VendorItem[]>(`${this.URL}/GetVendorList/${supplierId}/${taxonomy.valueOf()}`);
  }

  getMasterList(taxonomy: TypeId): Observable<MasterItem[]> {
    return this.http.get<MasterItem[]>(`${this.URL}/GetMasterList/${taxonomy.valueOf()}`);
  }

  updateSystemItemId(id: number, systemItemId: number): Observable<any> {
    return this.http.get(`${this.URL}/UpdateSystemItemId/${id}/${systemItemId}`)
      .pipe(catchError(this.handleError));
  }

  async updateProducts(vendorId: number, taxonomy: TypeId): Promise<void> {
    console.log(vendorId, taxonomy);
    var taxonomyId = TypeId[taxonomy] as unknown as number;
    const response = await this.http.get<Task>(`${this.URL}/UpdateProductsAfterMapping/${vendorId}/${taxonomyId}`).toPromise();
  }

  private handleError(error: any) {
    console.error('An error occurred:', error);
    return throwError(error);
  }

  public getRelatedProducts(id: number,typeId:TypeId): Promise<RelatedProductsMappingItem[]> {
    var taxonomyId = TypeId[typeId] as unknown as number;
    return this.http.get<RelatedProductsMappingItem[]>(`${this.URL}/MappingProductHelperItem/${id}/${taxonomyId}`)
      .toPromise()
      .then(data => data || []); // Handle the case where data might be undefined
  }

}
