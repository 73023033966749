import { TypeId } from "./vendorItem.model";

export class Criterion {
    id:number;
    typeId:TypeId;
    valueItemId:number;
    creationDate:Date;

    constructor() {
    //    this.typeId=typeId;
    //    this.valueItemId =valueItemId;
    }
}
