<!-- Container-fluid starts -->
<div class="container-fluid steps-container">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-lg-4">
                            <div class="u-steps u-steps-vertical">
                                <div *ngFor="let step of steps" class="u-step" [class.current]="step === currentStep">
                                    <span class="u-step-number">{{ step }}</span>
                                    <div class="u-step-desc">
                                        <span class="u-step-title">{{ getTitleByStep(step) }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
