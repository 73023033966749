import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NgbModule, NgbRatingModule } from '@ng-bootstrap/ng-bootstrap';
import { AppComponent } from './app.component';
import { RouterModule } from '@angular/router';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { SaveInBrowser } from './core/SaveInBrowser';
import { ProductsService } from './Products/products.service';
import { CommonModule } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MappingModule } from './mapping/mapping.module';
import { JwtInterceptor } from './JwtInterceptor';
import { ProductListingModule } from './product-listing/product-listing.module';
import { AccessTokenModalComponent } from './client-integration/access-token-modal/access-token-modal.component';
import { AuthInterceptor } from './core/AuthInterceptor';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    NgbModule,
    NgbRatingModule,
    ReactiveFormsModule,
    AppRoutingModule,
    CommonModule,
    BrowserAnimationsModule,
    MappingModule,
    ProductListingModule,
    ToastrModule.forRoot(),
  ],
  exports: [RouterModule],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: JwtInterceptor,
    multi: true,
  },
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  ProductsService, SaveInBrowser],
  bootstrap: [AppComponent],
})
export class AppModule {}
