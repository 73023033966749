export class VendorItem {
    id: number;
    supplierId: SupplierId;
    systemItemId: number;
    systemItemValue?: string | null;
    idName?: string;
    typeId: TypeId;
}

export enum TypeId {
    Categories = 1,
    Sizes = 2,
    Brands = 3,
    Colors = 4,
    Seasons = 5,
}

export enum SupplierId {
    StyleIsNow = 1001,
    Beestore = 1002,
    Vietti = 1003
}
