import { JWTTokenResponse } from '../Models/JWTTokenResponse.model';

// need to update to storage.service.ts
export class SaveInBrowser {
  saveInBrowser(response: JWTTokenResponse, userName: string) {
    // Access the response object here

    const token = response['token'];
    const success = response['success'];

    if (success) {
      this.setItem('user', { username: userName, token: token });
      // localStorage.setItem(
      //   'user',
      //   JSON.stringify({ username: userName, token: token })
      // );

    } else {
      // need to relocate to login page
      alert('Sign-in failed!');
    }
  }
  getItemBy(data: string): string | null {
    return localStorage.getItem(data);
  }

  setItem(key: string, value: any): void {
    localStorage.setItem(key, JSON.stringify(value));
  }
  removeItem(key: string): void {
    localStorage.removeItem(key);
  }
}
