export interface MenuItem {
  itemType: MenuItemType;
  name: string;
  params: MenuItemParams[];
  subMenu: MenuItem[];
  url: string | null;
  active: boolean;
  isOpen?: boolean;
}

export interface MenuItemParams {
  paramType: ParamType;
  value: number;
}

export enum ParamType {
  Category = 1,
  Tag,
  Brand,
}

export enum MenuItemType {
  Category = 1,
  Link = 2,
  CategoryWithLink = 3
}
