import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from '../auth/login.service';

export const authGuard = () => {
  const router = inject(Router);
  const authService = inject(LoginService);

  if (authService.isLoggedInUser()) {
    return true;
  }

  return router.createUrlTree(['/auth']);
};
