<div class="sidenav">
  <!--<a routerLink="/login">login</a>-->
  <!--<a routerLink="/forgot-password">forgot-password</a>
  <a routerLink="/register">register</a>-->

</div>

<div class="main">
  <router-outlet></router-outlet>
  <!--<app-login></app-login>-->
</div>
