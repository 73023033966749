import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MappingFormComponent } from './mapping-form/mapping-form.component';
import { NgbCollapseModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxPrintModule } from 'ngx-print';
import { NgxSliderModule } from 'ngx-slider-v2';
import { GalleryModule } from '@ks89/angular-modal-gallery';
import { MappingRoutingModule } from './mapping-routing.module';
import { FilterByUnmappedPipe } from './filter-by-unmapped.pipe';
import { PhotoCarouselComponent } from './photo-carousel/photo-carousel.component';



@NgModule({
  declarations: [MappingFormComponent, FilterByUnmappedPipe, PhotoCarouselComponent],
  imports: [
    CarouselModule,
    MappingRoutingModule,
    NgbModule,
    CommonModule,
    CarouselModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPrintModule,
    NgxSliderModule,
    GalleryModule,
    NgbCollapseModule,
  ]
})
export class MappingModule { }
