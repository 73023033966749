import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { StatusId } from 'src/app/Models/organization.model';
import { ProductListing } from 'src/app/Models/productListing.model';
import { ProductListingService } from '../product-listing.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NavService } from 'src/app/core/services/nav.service';
import { CheckStatus } from 'src/app/Models/checkStatus.model';

@Component({
  selector: 'app-product-listing-list',
  templateUrl: './product-listing-list.component.html',
  styleUrls: ['./product-listing-list.component.scss']
})
export class ProductListingListComponent {

  loadingStatus: { [key: string]: boolean } = {};
  tableProductListings$: Observable<ProductListing[] | null>;
  StatusId = StatusId;
  constructor(public service: ProductListingService, private router: Router, private route: ActivatedRoute, private navServices: NavService) {
    this.tableProductListings$ = this.service.productListings$;
  }

  toggleButton(productListing: ProductListing) {
  }

  private navigateToProductListingForm(mode: 'create' | 'edit', productListing?: ProductListing) {
    this.service.setProductListingData(productListing);

    this.router.navigate([`./${mode}`], {
      relativeTo: this.route,
      queryParams: {}
    });
  }
  remove(id: number) {
    this.service.remove(id);
  }

  edit(productListing: ProductListing) {
    this.navigateToProductListingForm('edit', productListing);
  }

  downlodExcel(productListing: ProductListing) {
    // set spinner
    this.loadingStatus[productListing.id] = true;

    this.service.createReportFile(productListing.id).subscribe(
      (res) => {
        let intervalId = setInterval(() => {
          this.service.getProductListingStatus(productListing.id).subscribe(
            (result: CheckStatus) => {
              if (result.isReady === true) {
                clearInterval(intervalId);
                this.downloadExcel(result.url, `${productListing.name}.xlsx`)
                  .subscribe(
                    () => {
                      this.loadingStatus[productListing.id] = false;
                    },
                    (error) => {
                      console.error('Error downloading Excel file:', error);
                      this.handleDownloadError(error.error.errorCode, productListing.id)
                    }
                  );
              }
            },
            (error) => {
              console.error('Error checking status:', error);
              this.handleDownloadError(error.error.errorCode, productListing.id, intervalId as unknown as number)
            }
          );
        }, 5000);
      },
      (error) => {
        console.error('Error creating report file:', error);
        this.handleDownloadError(error.error.errorCode, productListing.id)
      }
    );
  }

  handleDownloadError(errorCode: number, productListingId: number, intervalId?: number) {
    if (errorCode === 1000) {
      alert("Downloading error. Please try again.");
      this.loadingStatus[productListingId] = false;
      if (intervalId !== undefined) {
        clearInterval(intervalId);
      }
    } else {
      // Handle other error cases as needed
      console.error('Unhandled error:', errorCode);
    }
  }

  downloadExcel(url: string, fileName: string): Observable<any> {
    return this.service.downloadExcel(url, fileName);
  }

  createProductListing() {
    this.navigateToProductListingForm('create', new ProductListing());
  }

  ngOnInit() {
    this.navServices.collapseSidebar = true;
  }

  ngOnDestroy(): void {
  }

}
