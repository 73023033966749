<div class="container-fluid">
    <div class="row align-items-start top-m">
        <div class="col-lg-2 d-none d-lg-block">
            <app-steps [currentStep]="currentStep" [steps]="stepsList"></app-steps>
        </div>
        <div class="col-lg-10">
            <div class="edit-profile">
                <div class="row">
                    <div class="col-lg-10">
                        <div class="card">
                            <div class="card-header">
                                <h4 class="card-title mb-0">{{ getTitleByStep() }}</h4>
                            </div>
                            <div class="card-body">
                                <form [formGroup]="productListingForm">
                                    <div [formGroup]="currentStepForm">
                                        <!-- Step 1: General Info -->
                                        <div *ngIf="currentStep === 1">
                                            <div class="card">
                                                <div class="card-body">
                                                    <label class="form-label">Name</label>
                                                    <div class="col">
                                                        <div class="form-group">
                                                            <input class="form-control" formControlName="Name"
                                                                id="Name">
                                                            <label class="red"
                                                                *ngIf="this.productListingForm.get('Name')?.hasError('required') && this.productListingForm.get('Name')?.touched">
                                                                This field is required
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-6 col-md-3">
                                                        <label class="form-label">Status</label>
                                                        <div class="toggle"
                                                            [class.disabled]="productListing.status !== StatusId.Enabled"
                                                            (click)="toggleButton(productListing)">
                                                            <i [class]="productListing.status !== StatusId.Enabled ? 'fa fa-toggle-off' : 'fa fa-toggle-on green'"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="currentStep === 2">
                                            <div class="card">
                                                <div class="card-body">
                                                    <div class="list-group">
                                                        <div class="list" *ngIf="getCategories().length > 0">
                                                            <a
                                                                class="list-group-item list-group-item-action active">Categories:</a>
                                                            <div *ngFor="let category of getCategoriesName()"
                                                                class="list-item">
                                                                <a class="list-group-item list-group-item-action">{{
                                                                    category.name }}
                                                                    <button (click)="removeCategory(category.id)"
                                                                        class="remove-button"><i
                                                                            class="fa fa-trash-o red"></i></button>
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div class="list" *ngIf="getBrands().length > 0">
                                                            <a
                                                                class="list-group-item list-group-item-action active">Brands:</a>
                                                            <div *ngFor="let brand of getBrandsName()"
                                                                class="list-item">
                                                                <a class="list-group-item list-group-item-action">{{
                                                                    brand.name
                                                                    }}
                                                                    <button (click)="removeBrand(brand.id)"
                                                                        class="remove-button"><i
                                                                            class="fa fa-trash-o red"></i></button>
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <button class="btn btn-add-critetia"
                                                            type="button" (click)="openVerticallyCentered(content)"
                                                            *ngIf="currentStep < steps.length">
                                                            <i  class="fa fa-plus"></i>
                                                            Add New Criteria
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- Step 3: Products -->
                                        <div *ngIf="currentStep === 3">
                                            <ng-container *ngIf="products$ | async as products">
                                                <div *ngIf="products.totalProducts > 0; else notFound">
                                                    <div class="col-sm-12">
                                                        <div class="card">
                                                            <div class="card-body">
                                                                <div
                                                                    class="table-responsive product-list-custom server-datatable">
                                                                    <div class="table-responsive">
                                                                        <table class="table">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th scope="col">Image</th>
                                                                                    <th scope="col">Name</th>
                                                                                    <th scope="col">Desc</th>
                                                                                    <th scope="col">Price</th>
                                                                                    <th scope="col">Stock</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                <tr
                                                                                    *ngFor="let product of products?.products; index as i">
                                                                                    <td>
                                                                                        <img [src]='product.image'
                                                                                            class="me-2 img-fluid"
                                                                                            style="width: 40px">
                                                                                    </td>
                                                                                    <td>
                                                                                        {{ product.name }}
                                                                                    </td>
                                                                                    <td>{{ product.description }}</td>
                                                                                    <td>{{ product.price }}</td>
                                                                                    <td>
                                                                                        <div [ngClass]="{
                                                                                  'in-stock': product.quantity > 0,
                                                                                  'out-of-stock': product.quantity === 0
                                                                                }">
                                                                                            {{ product.quantity > 0 ?
                                                                                            'In Stock'
                                                                                            : 'Out of Stock' }} </div>
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="centered-div">
                                                        <div class="bottom-wrapper">
                                                            <div class="col-md-12 text-start">
                                                                <span class="f-w-600 m-r-10">Showing Products
                                                                    {{ products.pageSize * (products.currentPage - 1) +
                                                                    1 }} -
                                                                    {{
                                                                    +products.totalProducts >
                                                                    products.pageSize * products.currentPage
                                                                    ? products.pageSize * products.currentPage
                                                                    : +products.totalProducts
                                                                    }}
                                                                    Of {{ products.totalProducts }} Results</span>
                                                            </div>
                                                            <div class="pagination-responsive">
                                                                <ngb-pagination
                                                                    [collectionSize]="(+products.totalProducts / +products.pageSize) * 10"
                                                                    [(page)]="products.currentPage" [maxSize]="5"
                                                                    [rotate]="true" [ellipses]="false"
                                                                    [boundaryLinks]="true"
                                                                    (pageChange)="updatePage($event)"></ngb-pagination>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-container>
                                            <div class="col-sm-12">
                                                <ng-template #notFound>
                                                    <div class="search-not-found text-center">
                                                        <!-- <img src="assets/images/search-not-found.png" alt=""
                                                            class="second-search" /> -->
                                                        <p>Sorry, We didn't find any results matching this search</p>
                                                    </div>
                                                </ng-template>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                <div class="card-footer btns-row">
                                    <button class="btn outline" type="button" (click)="cancel()"
                                        *ngIf="currentStep === 1">
                                        Cancel
                                    </button>
                                    <button class="btn outline" type="button" (click)="prevStep()"
                                        *ngIf="currentStep > 1">
                                        Previous
                                    </button>
                                    <button class="btn" type="button" (click)="nextStep()"
                                        *ngIf="currentStep < steps.length" [disabled]="!isCurrentStepFormValid()">
                                        Next
                                    </button>
                                    <button class="btn" type="button" (click)="saveProfile()"
                                        *ngIf="currentStep === steps.length">
                                        Save
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- ... Other HTML code ... -->
            <div class="container-fluid">
                <div class="row">
                    <div class="col-sm-12 col-xl-12">
                        <div class="card modal">
                            <div class="card-body modal">
                                <ng-template #content let-modal>
                                    <div class="modal-header" style="border-bottom: 0px">
                                        <button type="button" class="btn-close" aria-label="Close"
                                            (click)="modal.dismiss('Cross click')"></button>
                                    </div>
                                    <div class="modal-body" style="padding: 0%">
                                        <app-criteria [productListing]="productListing"
                                            (criteriaSelected)="criteriaSelected($event)"
                                            (closeModal)="closeModal()"></app-criteria>
                                    </div>
                                </ng-template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
