import { Component, Input, ViewEncapsulation } from '@angular/core';
@Component({
  selector: 'app-steps',
  templateUrl: './steps.component.html',
  styleUrls: ['./steps.component.scss']
})
export class StepsComponent {
  @Input() currentStep: number;
  @Input() steps: number[];

  getTitleByStep(step: number): string {

    switch (step) {
      case 1:
        return 'General Information';
      case 2:
        return 'Categories and Brands';
      case 3:
        return 'Products';
      default:
        return 'Step ' + step;
    }
  }
}
