import { NavService } from 'src/app/core/services/nav.service';
import { Component, Input } from '@angular/core';
import { MappingService } from '../mapping.service';
import { SupplierId, TypeId, VendorItem } from 'src/app/Models/vendorItem.model';
import { MasterItem } from 'src/app/Models/masterItem.model';
import { Subject, observable, takeUntil } from 'rxjs';
import { RelatedProductsMappingItem } from 'src/app/Models/relatedProductsMappingItem.model';
import { ModalDismissReasons, NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { PhotoCarouselComponent } from 'src/app/mapping/photo-carousel/photo-carousel.component';

@Component({
  selector: 'app-mapping-form',
  templateUrl: './mapping-form.component.html',
  styleUrls: ['./mapping-form.component.scss']
})
export class MappingFormComponent {
  [x: string]: any;
  vendorItemList: VendorItem[];
  masterItemList: MasterItem[];
  vendorIdList: SupplierId[] = [SupplierId.StyleIsNow, SupplierId.Beestore, SupplierId.Vietti];
  taxonomyList = Object.values(TypeId).filter(value => typeof value === 'string');
  selectedVendor: SupplierId | undefined = undefined;
  selectedTaxonomy: TypeId;
  selectedVendorItem: VendorItem | null = null;
  selectedMasterItem: MasterItem | null = null;
  SupplierId = SupplierId;
  relatedProducts : RelatedProductsMappingItem[];
  masterItemSearchTerm: string = ''; // Variable to store the search term
  vendorItemSearchTerm: string = ''; // Variable to store the search term
  filteredMasterItemList: MasterItem[]; // Variable to store the filtered master item list
  filteredVendorItemList: VendorItem[];
  isUnmappedOnly: boolean = true;
  private onDestroy$ = new Subject<void>();
  constructor(private navServices: NavService, private mappingService: MappingService,config: NgbModalConfig, private modalService: NgbModal) {
    console.log('MappingFormComponent instantiated');
    config.backdrop = 'static';
    config.keyboard = false;
  }

  ngOnInit(): void {
    this.navServices.collapseSidebar = true;
  }

  public selectVendor(vendor: SupplierId): void {
    this.selectedVendor = vendor;
    this.getList();
  }

  public selectTaxonomy(taxonomy: string | TypeId) {
    this.selectedTaxonomy = taxonomy as TypeId;
    this.getList();
  }

  public updateProducts(): void {
    if (this.selectedVendor !== undefined && this.selectedTaxonomy !== undefined) {
      this.mappingService.updateProducts(this.selectedVendor, this.selectedTaxonomy);
    } else {
      console.error('Selected vendor or taxonomy is undefined.');
    }
  }


  public selectMasterItem(masterItem: MasterItem): void {
    if (this.selectedMasterItem === masterItem) {
      this.selectedMasterItem = null;
    } else {
      this.selectedMasterItem = masterItem;
    }
    if (this.selectedVendorItem?.id !== undefined && this.selectedMasterItem?.id !== undefined) {
      this.updateSystemItemId(this.selectedVendorItem?.id, this.selectedMasterItem?.id);
      const selectedVendorItemIndex = this.vendorItemList.findIndex(item => item.id === this.selectedVendorItem?.id);
      this.vendorItemList[selectedVendorItemIndex].systemItemValue = this.selectedMasterItem.name;
    }
  }

  public selectVendorItem(vendorItem: VendorItem): void {
    if (this.selectedVendorItem === vendorItem) {
      this.selectedVendorItem = null;
    } else {
      this.selectedVendorItem = vendorItem;
    }
    if (this.selectedMasterItem?.id !== undefined && this.selectedVendorItem?.id !== undefined) {
      this.updateSystemItemId(this.selectedVendorItem?.id, this.selectedMasterItem?.id);
      const selectedVendorItemIndex = this.vendorItemList.findIndex(item => item.id === vendorItem.id);
      this.vendorItemList[selectedVendorItemIndex].systemItemValue = this.selectedMasterItem.name;
    }
  }


  public removeVendorItemMapping(vendorItem: VendorItem): void {
    if (vendorItem?.id !== undefined) {
      this.updateSystemItemId(vendorItem.id, 0);
      const selectedVendorItemIndex = this.vendorItemList.findIndex(item => item.id === vendorItem.id);
      this.vendorItemList[selectedVendorItemIndex].systemItemValue = null;
    }
  }


  public updateSystemItemId(id: number, systemItemId: number): void {
    if (id !== undefined && systemItemId !== undefined) {
      this.mappingService.updateSystemItemId(id, systemItemId).pipe(takeUntil(this.onDestroy$)).subscribe(result => {
        if (result) {
          const selectedVendorItemIndex = this.vendorItemList.findIndex(item => item.id === id);

          if (selectedVendorItemIndex !== -1) {
            if (systemItemId !== undefined) {
              this.vendorItemList[selectedVendorItemIndex].systemItemId = systemItemId;
            }
          }

          // Clear selected items
          this.selectedVendorItem = null;
          this.selectedMasterItem = null;
          // this.filteredMasterItemList = this.masterItemList;
          // this.masterItemSearchTerm = "";
        } else {

          // Handle failure or error
          console.error('Failed to update system item ID.');
        }
      });
    }
  }


  public getList() {
    if (this.selectedVendor && this.selectedVendor != undefined && this.selectedTaxonomy) {

      var taxonomy = TypeId[this.selectedTaxonomy] as unknown as number;
      this.mappingService.getMasterList(taxonomy).pipe(takeUntil(this.onDestroy$)).subscribe((data) => {
        this.masterItemList = data;
        this.filteredMasterItemList = this.masterItemList
      });

      this.mappingService.getVendorList(this.selectedVendor, taxonomy).pipe(takeUntil(this.onDestroy$)).subscribe((data) => {
        this.vendorItemList = data;
        this.filteredVendorItemList = this.vendorItemList;
        console.log(this.filteredVendorItemList);
      });
    }
  }


  public filterMasterItems(): void {
    this.filteredMasterItemList = this.masterItemList.filter(item =>
      item.name.toLowerCase().includes(this.masterItemSearchTerm.toLowerCase())
    );
  }

  public filterVendorItems(): void {
    this.filteredVendorItemList = this.vendorItemList.filter(item =>
      item.idName?.toLowerCase().includes(this.vendorItemSearchTerm.toLowerCase())
    );
  }

  content:string;

  async openVerticallyCentered(content,id) {
    try {
      this.relatedProducts = await this.mappingService.getRelatedProducts(id,this.selectedTaxonomy);
      console.log(this.relatedProducts);
    } catch (error) {
      console.error('Error fetching related products:', error);
    }
    this.modalService.open(content, { centered: true });
  }



  ngOnDestroy(){
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
