import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MasterItem } from 'src/app/Models/masterItem.model';
import { ProductListing } from 'src/app/Models/productListing.model';
import { TypeId } from 'src/app/Models/vendorItem.model';
import { ProductListingService } from '../product-listing.service';
import { Observable, of, tap } from 'rxjs';
import { Criterion } from 'src/app/Models/criterion.model';

@Component({
  selector: 'app-criteria',
  templateUrl: './criteria.component.html',
  styleUrls: ['./criteria.component.scss']
})
export class CriteriaComponent {


  constructor(private service: ProductListingService) { }

  @Input()
  productListing: ProductListing;

  @Output() criteriaSelected = new EventEmitter<Criterion>();
  @Output() closeModal = new EventEmitter<void>();

  selectedTaxonomy: TypeId;
  selectedItem: MasterItem;

  taxonomyTypes: TypeId[] = [TypeId.Categories, TypeId.Brands];

  taxonomyValuesList: MasterItem[] | null;

  ngOnInit() {
    console.log('product Listing:', this.productListing);
  }

  selectTaxonomy(taxonomy: TypeId) {
    this.selectedTaxonomy = taxonomy;
    let observable: Observable<MasterItem[] | null> = of(null);

    if (this.selectedTaxonomy === TypeId.Categories) {
      observable = this.service.categories$;
    } else if (this.selectedTaxonomy === TypeId.Brands) {
      observable = this.service.brands$;
    }

    observable.pipe(
      tap(values => {
        this.taxonomyValuesList = values;
      })
    ).subscribe();
  }

  getTaxonomy(taxonomyId: number) {
    return TypeId[taxonomyId];
  }

  selectItem(item: MasterItem) {
    var newItem = new Criterion();

    if (this.selectedTaxonomy == TypeId.Categories) {
      newItem.typeId = TypeId.Categories;
    }
    else if (this.selectedTaxonomy == TypeId.Brands) {
      newItem.typeId = TypeId.Brands;
    }
    newItem.valueItemId = item.id;
    this.criteriaSelected.emit(newItem);
    this.closeModal.emit();
  }
}
