<div class="container">
  <div class="row justify-content-center mt-3">
    <h3>Mapping</h3>
    <div class="col-md-12 mb-2">
      <div ngbDropdown class="d-inline-block" placement="bottom">
        <label class="form-label" for="vendorSelector">Selected Vendor:</label>
        <button class="btn btn-outline-primary" id="dropdownBasic1" ngbDropdownToggle>
          {{ selectedVendor !== undefined ? SupplierId[selectedVendor] : 'Select Vendor' }}
        </button>
        <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
          <button *ngFor="let vendor of vendorIdList" class="dropdown-item" (click)="selectVendor(vendor)">
            {{ SupplierId[vendor]}}
          </button>
        </div>
      </div>
    </div>
    <div class="col-md-12 mb-2">
      <div ngbDropdown class="d-inline-block" placement="bottom">
        <label class="form-label" for="taxonomySelector">Selected Taxonomy:</label>
        <button class="btn btn-outline-primary" id="dropdownBasic2" ngbDropdownToggle>
          {{ selectedTaxonomy || 'Select Taxonomy' }}
        </button>
        <div ngbDropdownMenu aria-labelledby="dropdownBasic2">
          <button *ngFor="let taxonomy of taxonomyList" class="dropdown-item" (click)="selectTaxonomy(taxonomy)">
            {{ taxonomy }}
          </button>
        </div>
      </div>
    </div>
    <div class="col-md-12">
      <div class="form-check">
        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" [(ngModel)]="isUnmappedOnly">
        <label class="form-check-label" for="flexCheckDefault">
          Show only unmapped items
        </label>
      </div>
    </div>
    <div class="col-md-12" *ngIf="selectedTaxonomy && selectedVendor">
      <div class="form-check custom-align-right">
        <button class="btn btn-outline-primary" (click)="updateProducts()">
          Update products
        </button>
      </div>
    </div>
  </div>
  <div class="row data" *ngIf="selectedTaxonomy && selectedVendor">
    <!-- Vendor List -->
    <div class="col-sm-6">
      <div class="sticky-top pb-1">
        <h5>Vendor List</h5>
        <div class="form-group mb-2">
          <input class="form-control" type="search" [(ngModel)]="vendorItemSearchTerm" name="search"
            placeholder="Search vendor items.." (input)="filterVendorItems()" />
          <i class="icon fa fa-search"></i>
        </div>
      </div>
      <div class="card">
        <div class="card-body vertical-tab">
          <ul ngbNav class="nav-tabs">
            <li class="list-item"
              *ngFor="let vendorItem of filteredVendorItemList | filterByUnmapped: isUnmappedOnly; let i = index"
              [ngbNavItem]="i + 1">
              <div class="button-container">
                <div ngbNavLink [class.active]="vendorItem?.id === selectedVendorItem?.id"
                  (click)="selectVendorItem(vendorItem)">
                  <div class="">
                    <img *ngIf="vendorItem.systemItemId !== 0" class="check-icon"
                      src="../../../../assets/images/check.png" alt="V Icon" />
                    <span>{{ vendorItem?.idName }}</span>
                    <span *ngIf="vendorItem?.systemItemValue !== null"><b> - {{ vendorItem?.systemItemValue
                        }}</b></span>
                  </div>
                  <div class="icon-container">
                    <i *ngIf="vendorItem.systemItemId !== 0" class="fa fa-times-circle-o fa-2x x-mark-icon"
                        (click)="removeVendorItemMapping(vendorItem)"></i>
                    <i class="fa fa-image fa-image-icon" (click)="openVerticallyCentered(content, vendorItem?.id)"></i>
                </div>
                  <!-- <i *ngIf="vendorItem.systemItemId !== 0" class="btn btn-link x-mark-button"
                    class="fa fa-times-circle-o fa-2x" (click)="removeVendorItemMapping(vendorItem)"></i>
                  <i class="fa fa-image" (click)="openVerticallyCentered(content,vendorItem?.id)"></i> -->
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- Master List -->
    <div class="col-sm-6">
      <div class="sticky-top pb-1">
        <h5>Master List</h5>
        <div class="form-group mb-2">
          <input class="form-control" type="search" [(ngModel)]="masterItemSearchTerm" name="search"
            placeholder="Search master items.." (input)="filterMasterItems()" />
          <i class="icon fa fa-search"></i>
        </div>
      </div>
      <div class="card">
        <div class="card-body vertical-tab">
          <ul ngbNav #masterNav="ngbNav" class="nav-tabs">
            <li class="list-item" *ngFor="let masterItem of filteredMasterItemList; let i = index" [ngbNavItem]="i + 1">
              <div class="button-container">
                <div ngbNavLink [class.active]="masterItem?.id === selectedMasterItem?.id"
                  (click)="selectMasterItem(masterItem)">{{ masterItem?.name }}</div>
              </div>
            </li>
          </ul>
          <div [ngbNavOutlet]="masterNav" class="mt-2"></div>
        </div>
      </div>
    </div>
  </div>

  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12 col-xl-12">
        <div class="card">
          <div class="card-body">
            <ng-template #content let-modal>
              <div class="modal-header" style="border-bottom: 0px">
                <button type="button" class="btn-close" aria-label="Close"
                  (click)="modal.dismiss('Cross click')"></button>
              </div>
              <div class="modal-body" style="padding: 0%">
                <app-photo-carousel [relatedProducts]="relatedProducts"></app-photo-carousel>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>