import { Criterion } from "./criterion.model";
import { StatusId } from "./organization.model";

export class ProductListing {
    id: number;
    name: string;
    organizationId: number;
    status: StatusId;
    creationDate: Date;
    criteria: Criterion[];

    constructor() {
        this.status = StatusId.Enabled;
        this.criteria = [];
    }
}
