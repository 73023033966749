export class JWTTokenResponse {
  token: string;
  success: boolean;
  permissions: Permission[]
  constructor() {
    this.token = "";
    this.success = true;
    this.permissions = [];
  }
}

export interface Permission {
  scurityCode: string;
  permission: PermissionType;
}

export enum PermissionType {
  Edit = 1,
  View = 2,
  Deny = 4
}
