<div class="container">
    <div class="row justify-content-center">
        <div class="col-sm-6">
            <div ngbDropdown class="d-flex align-items-center gap-2" placement="bottom">
                <label class="form-label" for="taxonomySelector">Selected Taxonomy:</label>
                <button class="btn btn-outline-primary" id="dropdownBasic2" ngbDropdownToggle>
                    {{ getTaxonomy(selectedTaxonomy) || 'Select Taxonomy' }}
                </button>
                <div ngbDropdownMenu aria-labelledby="dropdownBasic2">
                    <button *ngFor="let taxonomy of taxonomyTypes" class="dropdown-item"
                        (click)="selectTaxonomy(taxonomy)">
                        {{getTaxonomy(taxonomy) }}
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="row justify-content-center scroll" *ngIf="selectedTaxonomy">
        <div class="col-sm-6">
            <div class="card">
                <div class="card-body vertical-tab">
                    <ul ngbNav #masterNav="ngbNav" class="nav-tabs">
                        <li class="list-item" *ngFor="let item of taxonomyValuesList; let i = index"
                            [ngbNavItem]="i + 1">
                            <div class="button-container">
                                <div ngbNavLink [class.active]="item?.id === selectedItem?.id"
                                    (click)="selectItem(item)">{{ item?.name }}</div>
                            </div>
                        </li>
                    </ul>
                    <div [ngbNavOutlet]="masterNav" class="mt-2"></div>
                </div>
            </div>
        </div>
    </div>
</div>
