<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header">
                    <h5>Products</h5>
                </div>
                <div class="card-body">
                    <owl-carousel-o [options]="owlcarousel1Options">
                        <ng-template carouselSlide class="item" *ngFor="let product of relatedProducts">
                            <img [src]="product.mainImage" style="width: 10vw;" alt="product.name" />
                            <div><b>name: </b>{{ product.name }}</div><br>
                            <!-- <div><b>description: </b>{{ product.description }}</div><br> -->
                            <div><b>sku: </b>{{ product.sku }}</div>
                        </ng-template>
                    </owl-carousel-o>
                </div>
                <div class="text-center" *ngIf="!relatedProducts || relatedProducts.length === 0">
                    <p>No products available</p>
                </div>
            </div>
        </div>
    </div>
</div>