import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable, Subject, catchError, map, of, switchMap } from 'rxjs';
import { ProductListing } from '../Models/productListing.model';
import { MasterItem } from '../Models/masterItem.model';
import { ProductDisplayResponse } from '../Models/productDisplayResponse.model';
import { HttpClient } from '@angular/common/http';
import { StatusId } from '../Models/organization.model';
import { Criterion } from '../Models/criterion.model';
import { ProductDisplay } from '../Models/productDisplay.model';
import { ProductDisplayRequest } from '../Models/productDisplayRequest.model';
import { Dictionary } from 'lodash';
import { CheckStatus } from '../Models/checkStatus.model';

@Injectable({
  providedIn: 'root'
})
export class ProductListingService {

  private URL = `${environment.apiUrl}/ProductListing`;
  private _http: any = null;

  private productListingsSubject = new BehaviorSubject<ProductListing[] | null>(null);
  productListings$ = this.productListingsSubject.asObservable();

  private selectedProductListSubject = new BehaviorSubject<ProductListing | null>(null);
  selectedProductList$ = this.selectedProductListSubject.asObservable();

  private categoriesSubject = new BehaviorSubject<MasterItem[] | null>(null);
  categories$ = this.categoriesSubject.asObservable();

  private brandsSubject = new BehaviorSubject<MasterItem[] | null>(null);
  brands$ = this.brandsSubject.asObservable();

  private productListSubject = new BehaviorSubject<ProductDisplayResponse | null>(null);
  productList$ = this.productListSubject.asObservable();

  private myOrganizationSubject = new BehaviorSubject<MasterItem | null>(null);
  myOrganization$ = this.myOrganizationSubject.asObservable();

  private productListingIdChangeSubject = new BehaviorSubject<number | null>(null);
  productListingIdChange$ = this.productListingIdChangeSubject.asObservable();

  private criteriaSubject = new BehaviorSubject<Criterion[]>([]);

  dictionaryCategories: Dictionary<number, MasterItem> = {};
  dictionaryBrands: Dictionary<number, MasterItem> = {};

  constructor(private http: HttpClient) {
    this._http = http;
    this.getProductListings();
    this.getMyOrganization();
    this.saveCategories();
    this.saveBrands();
  }

  ngOnInit() {
  }

  getProductListings(): void {

    this.http.get<ProductListing[]>(`${this.URL}/product-listings`).subscribe(
      (data) => {
        this.productListingsSubject.next(data);
      },
      (error) => {
        console.error('Error fetching user data:', error);
      }
    );
  }

  getCriteria(id: number): Observable<Criterion[]> {
    this.http.get<Criterion[]>(`${this.URL}/criteria-listing/${id}`).subscribe(
      (data) => {
        this.criteriaSubject.next(data);
      },
      (error) => {
        console.error('Error fetching criteria:', error);
      }
    );
    return this.criteriaSubject.asObservable();
  }

  setCriteria(criteria: Criterion[]): void {
    this.criteriaSubject.next(criteria);
  }

  getMyOrganization(): void {

    this.http.get<MasterItem>(`${this.URL}/my-organization`).subscribe(
      (data) => {
        this.myOrganizationSubject.next(data);
      },
      (error) => {
        console.error('Error fetching user data:', error);
      }
    );
  }

  getProductList(request: ProductDisplayRequest): Observable<ProductDisplayResponse | null> {
    return this.http.post<ProductDisplayResponse>(`${this.URL}/products`, request);
  }

  setProducts(response: ProductDisplayResponse): void {
    this.productListSubject.next(response);
  }

  updateProductListingId(newProductListingId: number): void {
    this.productListingIdChangeSubject.next(newProductListingId);
  }

  saveProductListing(productListing: ProductListing, mode: string) {

    if (this.myOrganizationSubject.getValue() === null) {
      this.getMyOrganization();
    }

    productListing.organizationId = this.myOrganizationSubject.getValue()?.id ?? 0;
  
    if (mode === "create") {
      return this._http.post(`${this.URL}/create-product-listing`, productListing)
      .pipe(
        map(response => {
          this.getProductListings();
          return "OK";
        })
      );        
    }
    if (mode === "edit") {

      return this._http.post(`${this.URL}/edit-product-listing`, productListing)
      .pipe(
        map(response => {
          const productListings = this.productListingsSubject.getValue();

          if (productListings) {
            const updatedProductListings = productListings.map(element => (element.id === productListing.id) ? productListing : element);
            this.productListingsSubject.next(updatedProductListings);
          }
          return "Error"; 
        })
      );  
    }
    return of('Error');
  }

  remove(id: number): void {
    this.http.delete(`${this.URL}/delete-product-listing/${id}`).subscribe(
      (res) => {
        this.removeFromLocalData(id);
      },
      (error) => {
        console.error('Error fetching user data:', error);
      }
    );
  }

  removeFromLocalData(id: number): void {
    const productListings = this.productListingsSubject.getValue();

    if (productListings) {
      const list = productListings.filter(productListing => productListing.id !== id);
      this.productListingsSubject.next(list);
    }
  }

  setProductListingData(productListing?: ProductListing) {
    this.selectedProductListSubject.next(productListing ?? null);
  }

  saveCategories() {
    this.http.get<MasterItem[] | null>(`${this.URL}/categories`).subscribe(
      (data) => {
        this.categoriesSubject.next(data);
        if (data) {
          this.dictionaryCategories = this.buildDictionary(data);
        }
      },
      (error) => {
        console.error('Error fetching getCategories:', error);
      }
    );
  }

  saveBrands() {
    this.http.get<MasterItem[] | null>(`${this.URL}/brands`).subscribe(
      (data) => {
        this.brandsSubject.next(data);
        if (data) {
          this.dictionaryBrands = this.buildDictionary(data);
        }
      },
      (error) => {
        console.error('Error fetching getBrands:', error);
      }
    );
  }

  createReportFile(productListingId: number) {
    return this.http.post(`${this.URL}/create-report-file/${productListingId}`, null);
  }

  getProductListingStatus(productListingId: number) {
    return this.http.get<CheckStatus>(`${this.URL}/report-file-status/${productListingId}`).pipe(
      catchError((error) => {
        throw error;
      })
    );
  }

  getBrands() {
    return this.brandsSubject.getValue();
  }

  editStatus(ProductListingId: number, statusId: StatusId) {
    //check!!!
    this.http.get(`${this.URL}/edit-status/${ProductListingId}/${statusId}`,).subscribe(
      (data) => {
      },
      (error) => {
        console.error('Error in editStatus:', error);
      }
    );
  }

  private buildDictionary(items: MasterItem[]): Dictionary<number, MasterItem> {
    return items.reduce((dict, item) => {
      dict[item.id] = item;
      return dict;
    }, {} as Dictionary<number, MasterItem>);
  }

  getBrandById(id: number): MasterItem {
    return this.dictionaryBrands[id];
  }

  getCategoryById(id: number): MasterItem {
    return this.dictionaryCategories[id];
  }

  downloadExcel(url: string, fileName: string): Observable<any> {
    return new Observable(observer => {
      this.http.get(this.URL + url, { responseType: 'blob' }).subscribe(
        (excelBlob: Blob) => {
          const blob = new Blob([excelBlob], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

          // Create a link element, set its download attribute, and trigger a click event
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = fileName;
          link.click();

          // Clean up
          window.URL.revokeObjectURL(link.href);

          observer.next(); // Notify that the operation has completed
          observer.complete();
        },
        error => {
          observer.error(error);
          observer.complete();
        }
      );
    });
  }
}
