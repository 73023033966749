import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { Login } from '../Models/login.model';
import { JWTTokenResponse, Permission } from '../Models/JWTTokenResponse.model';
import { HttpClient } from '@angular/common/http';
import { SaveInBrowser } from '../core/SaveInBrowser';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LoginService {

  private readonly tokenKey = 'jwtToken';
  private userCodesSubject: BehaviorSubject<Permission[]> = new BehaviorSubject<Permission[]>([]);
  public userCodes$ = this.userCodesSubject.asObservable();
  //Login need to change to Auth
  URL = `${environment.apiUrl}/Login/Login/`;
  constructor(
    private _http: HttpClient,
    private _storageService: SaveInBrowser
  ) {}

  login(user: Login): Observable<JWTTokenResponse> {
    // return this._http.post<JWTTokenResponse>(this.URL, user);
    return this._http.post<JWTTokenResponse>(this.URL, user)
    .pipe(
      tap(response => {
        // Save the JWT token to local storage or wherever you want
        this._storageService.setItem(this.tokenKey, response.token);
        this._storageService.setItem('permissions', response.permissions);
      })
    );
  }

  logout(): void {
    this._storageService.removeItem(this.tokenKey);
    this._storageService.removeItem('permissions');
  }

  getUserCodes() {
    const permissions = this._storageService.getItemBy('permissions');
    if(permissions) {
      return JSON.parse(permissions);
    } else return [];
  }
  // isLoggedInUser(): boolean {
  //   return !!this._storageService.getItemBy('user');
  // }

  getJwtToken(): string | null {
    // Retrieve the JWT token from local storage or wherever you stored it
    return this._storageService.getItemBy(this.tokenKey);
  }

  isLoggedInUser(): boolean {
    return !!this.getJwtToken();
  }
}
